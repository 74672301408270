import React, {useEffect, useState} from 'react'
import Footer from './Footer'
import Header from './Header'
import MetaTags from 'react-meta-tags';
import {useLocation} from 'react-router-dom'

const Location = (props) => {
    const groupId = props.match.params.groupId;
    const [isEvents, setIsEvents] = useState(false);
    const [events, setEvents] = useState({})
    const companyName = 'Freehold Hospitality'
    const socialShareLink = 'https://poshtesting.s3.amazonaws.com/synchro-events/social-share.jpg'
    const metaDescription = "Freehold Hospitality - COME AS YOU ARE" 
    const metaTitle = 'Freehold Hospitality - COME AS YOU ARE'
    const website = 'https://www.freeholdbrooklyn.com/'
    const [trackingLink, setTrackingLink] = useState('')
    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    const getTitle = () => {
        if (groupId === '61b8e25d13376c0033b66a51') return 'MIAMI EVENTS'
        else if (groupId === '61ba489a42c0810033fe9e1f') return 'BROOKLYN EVENTS'
        else return 'UNION SQUARE EVENTS'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetch(`/poshAPI/${groupId}`)
            .then(async (res) => await res.json())
            .then(async (apiData) => {
                setEvents(apiData.events)
                setIsEvents(true)
            })
            if(query.get('t')){
                setTrackingLink(query.get('t'))
            }
    }, [isEvents]);

    
    return (
        <>
            <MetaTags>
                <title>{companyName}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="/assets/img/favicon.png" />
                <link href="/css/index.css" rel="stylesheet" />
                <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle}/>
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={socialShareLink} />
                <meta property="og:url" content={website} />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content={metaTitle} />
                <meta property="twitter:description" content={metaDescription} />
                <meta property="twitter:image" content={socialShareLink}/>
                <meta name="twitter:card" content="summary_large_image"></meta>
                <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Raleway" />
            </MetaTags>
            {(isEvents && events && events.length > 0) ?
            <div className='page'>
            <Header></Header>
        
            <div style={{ marginTop:'40px', position: 'relative', width: "100%", minHeight: "500px", display: 'flex', justifyContent: 'center' }}>
                <div className="body-inner-b">
                    <h3>{getTitle()}</h3>    
                        <>
                        <div className='locationWrapper'>
                            {events.map((event)=> (
                                <>
                                    <a className='featuredLinkLarge' href={trackingLink ? "https://posh.vip/e/" + event.url + "?t=" + trackingLink :"https://posh.vip/e/" + event.url}>
                                        <div className="ftce">
                                            <img src={event.flyer} className="featured-events-cont__events__main__flyer" />
                                            <div className="featured-events-cont__events__main__filter" />
                                            <img className="ftce-icon" />  
                                            <h3 className="ftce-title">{event.name}</h3>
                                            <div className="ftce-desc">{event.displayStart}</div>        
                                        </div>
                                    </a>
                                
                                </>
                            ))}
                            </div>
                        </>
                    
                </div>
            </div>
            <Footer></Footer>
            </div>
            : 
            <div className='boat-wrapper'>
                <img className='boatWheel' src={'https://poshtesting.s3.amazonaws.com/freehold/FREEHOLD-LOGO.png'} />
            </div>
            }
        </>
    )
}

export default Location