import React, {useState} from 'react'
import {Link} from 'react-scroll'

export default function Header() {
    const logo = 'https://poshtesting.s3.amazonaws.com/freehold/FREEHOLD-LOGO.png'
    return (
        <div className="header">
            <a className="header-icon" href="/">
                <img className='header-icon-img' src={logo}></img>
                {/* <div className='header-nav'>
                    <p><Link to={'MIAMI'} spy={true} smooth={true}>MIA</Link></p>
                    <p><Link to={'BROOKLYN'} spy={true} smooth={true}>BK</Link></p>
                </div> */}
            </a>
        </div>
    )
}
