import '../index.css';
import React from 'react'
import Index from './Index';
import Location from './Location';
import Login from './Login';
import Gallery from './Gallery';

import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Route exact path='/' component={Index}></Route>
        <Route path='/gallery/:galleryUrl' component={Gallery}></Route>
        <Route path='/location/:groupId' component={Location}></Route>
        <Route path='/login' component={Login}></Route>
      </Router>
    </>
  );
}

export default App;
