import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

import loading from '../assets/img/loading.png'
import MetaTags from 'react-meta-tags';
import ResponsiveGallery from 'react-responsive-gallery'

export default function Gallery(props) {
    const galleryUrl = props.match.params.galleryUrl;
    const [gallery, setGallery] = useState()
    const [isGallery, setIsGallery] = useState(false);
    const [images, setImages] = useState()
    const [eventName, setEventName] = useState()
    
    const companyName = 'Freehold Hospitality'
    const socialShareLink = 'https://poshtesting.s3.amazonaws.com/synchro-events/social-share.jpg'
    const metaDescription = "We are a team of ravers dedicated to creating life-altering experiences for people and push the art form of EDM forward. We believe that people who discover this community will naturally start to get in touch with their inner creativity which society has suppressed. This will open them up to who they really are which is the core idea behind Synchronicity. Through music and community it is our job at these events to leave a lasting impression of those who attend that they can carry into their everyday lives."
    const metaTitle = 'Freehold Hospitality - We are a team of ravers dedicated to creating life-altering experiences for people and push the art form of EDM forward. We believe that people who discover this community will naturally start to get in touch with their inner creativity which society has suppressed. This will open them up to who they really are which is the core idea behind Synchronicity. Through music and community it is our job at these events to leave a lasting impression of those who attend that they can carry into their everyday lives.'
    const website = 'https://synchroevents.com'
    
    const screenWidthSizes = {xs: 600, s: 800, m: 1080, l: 1080, xl: 1080, xxl: 3000}
    const numOfImagesPerRow = {xs: 1, s: 1, m: 2, l: 3, xl: 3, xxl: 3}
    const imagesPaddingBottom = {xs: 10, s: 10, m: 10, l: 10, xl: 10, xxl: 10}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        await fetch(`/poshAPI/gallery/${galleryUrl}`)
            .then(async (res) => await res.json())
            .then(async (galleryAPI) => {
                setGallery(galleryAPI.gallery);
                setEventName(galleryAPI.eventName)
                setGallery(true);
                const images = galleryAPI.gallery.imageUris.map(image => ({src: image}))
                setImages(images)
                setIsGallery(true)
            })
    }, [isGallery]);

    return (
        <body style={{backgroundImage: 'none'}} >
            <MetaTags>
                <title>{galleryUrl.charAt(0).toUpperCase() + galleryUrl.slice(1)} | {companyName}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="/assets/img/favicon.png" />
                <link href="/css/index.css" rel="stylesheet" />
                <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle}/>
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={socialShareLink} />
                <meta property="og:url" content={website} />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content={metaTitle} />
                <meta property="twitter:description" content={metaDescription} />
                <meta property="twitter:image" content={socialShareLink}/>
                <meta name="twitter:card" content="summary_large_image"></meta>
            </MetaTags>
            {isGallery && eventName && gallery && images && images.length > 0 ?
            <>
            <svg viewBox="0 0 100 100" preserveAspectRatio="none" id="svg-A">
                <defs>
                    <linearGradient id="linearA" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stop-color="#000000"></stop>
                        <stop offset="100%" stop-color="#FF3F3F"></stop>
                    </linearGradient>
                </defs>
                <path d="M 0 25 L 100 20 L 100 100 L 0 100 L 0 0" style={{ fill: '#FF3F3F' }}></path>
                <path d="M 0 30 L 100 20 L 100 100 L 0 100 L 0 0" style={{ fill: 'url(#linearA)' }}></path>
            </svg>
            <Header />
            <div style={{ marginTop: '40px', position: "relative", width: "80%", margin: 'auto' , display: "flex", justifyContent: "center" }}>
                
                <div className="body-inner-b-gallery">
                    
                    <a href="/">
                        <div className="back-link-category" style={{ color: "#9554ff" }}>← Back</div>
                    </a>
                    
                        <>
                        <h1 className='gallery-event-name'>{eventName}</h1>
                        <ResponsiveGallery
                            images={images ?? []}
                            screenWidthSizes={screenWidthSizes}
                            numOfImagesPerRow={numOfImagesPerRow}
                            imagesPaddingBottom={imagesPaddingBottom}
                            useLightBox={true}
                        />
                        </>
                    
                </div>
            </div>
            <Footer />
            </>
            :
            <>
                <div className='boat-wrapper'>
                    <img className='boatWheel' src={loading} />
                </div>
            </>
            }
            
        </body>
    )
}
