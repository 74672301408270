import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router';
import { db } from  '../firebase';
export default function Login() {
    const groupIds = ['61b8e25d13376c0033b66a51' ,'61ba489a42c0810033fe9e1f', '621fa79d74d9800033232f61']
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isEvents, setIsEvents] = useState(false);
    const [events, setEvents] = useState({})
    const [error, setError] = useState('');
    const usernameRef = useRef();
    const passwordRef = useRef();
    const [largeFeatured, setLargeFeatured] = useState('');
    const [secondFeatured, setSecondFeatured] = useState('');
    const [thirdFeatured, setThirdFeatured] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory()
    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true)
        setError('');
        let miamiEvents = []
        let brooklynEvents = []
        let unionSqEvents = []
        if (usernameRef.current.value === '123' && passwordRef.current.value === '123') {
            await fetch(`/poshAPI/${groupIds[0]}`)
            .then(async (res) => await res.json())
            .then(async (eventsAPI) => {
                if (eventsAPI.events) miamiEvents = eventsAPI.events;
            })
            await fetch(`/poshAPI/${groupIds[1]}`)
                .then(async (res) => await res.json())
                .then(async (eventsAPI) => {
                    if (eventsAPI.events) brooklynEvents =  eventsAPI.events;
                })
            await fetch(`/poshAPI/${groupIds[2]}`)
                .then(async (res) => await res.json())
                .then(async (eventsAPI) => {
                    if (eventsAPI.events) unionSqEvents = eventsAPI.events;
                    var allEvents = [].concat.apply([], [miamiEvents, brooklynEvents, unionSqEvents])
                    setIsEvents(true)
                    setEvents(allEvents)
                    setIsLoggedIn(true)
                    setIsLoading(false)
                })
        } else {
            setError('Incorrect username or password');
            setIsLoading(false)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    async function updateFeatured(e) {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        if(largeFeatured !== '' && secondFeatured !== '' && thirdFeatured !==''){
            var featuredEvents = [largeFeatured, secondFeatured, thirdFeatured];
            console.log(featuredEvents)
            Promise.all(featuredEvents.map(async(event, index) => {
                await db.collection('featured-items').doc(index.toString()).set({url: event.url})
                .then(() => {  
                    console.log(index);
                    if(index === 2){
                        history.push('/');
                        setIsLoading(false);
                    }
                    
                })
        }))
        }else{
            setError('Please pick an event for each item');
            setIsLoading(false);
        }
        
    }
    async function handleLargeFeaturedChange(e) {
        events.map((event) => {
            if (event.name === e.target.value) setLargeFeatured(event);
        })
    }
    async function handleSecondFeaturedChange(e) {
        events.map((event) => {
            if (event.name === e.target.value) setSecondFeatured(event);
        })
    }
    async function handleThirdFeaturedChange(e) {
        events.map((event) => {
            if (event.name === e.target.value) setThirdFeatured(event);
        })
    }

    return (
        <>
            {!isLoggedIn ?
                <>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <div className="login">
                        <form className="login-form" onSubmit={handleSubmit}>
                            <h3>Edit Featured Events</h3>
                            <li>
                            <label>
                                Username:  
                            <input type="text" ref={usernameRef} required />
                            </label>
                            </li>
                            <li>
                            <label>
                                Password:  
                            <input type="password" ref={passwordRef} required />
                            </label>
                            </li>
                            {isLoading ?
                            <p>Loading...</p>
                            :<button className="submit-button" type='submit'>Submit</button>}
                    </form>
                    </div>
                </> :
                <>
                    {
                        <>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                            {isEvents &&
                                <div className="login">
                                
                                <form className="login-form" onSubmit={updateFeatured}>
                                    <h3>Edit Featured Events</h3>
                                    <label>
                                        Select the first featured event
                                        <br></br>
                                        <select className="featured-picker" onChange={handleLargeFeaturedChange}>
                                            <option value="none" selected disabled >
                                                Select an Option
                                            </option>
                                            {events.map((event) => (
                                                <option value={event.name}>{`${event.name},${event.displayStart}`}</option>
                                            ))}
                                        </select>
                                        <br></br>
                                    </label>
                                    <label>
                                        Select the second featured event
                                        <br></br>
                                        <select className="featured-picker" onChange={handleSecondFeaturedChange}>
                                            <option value="none" selected disabled >
                                                Select an Option
                                            </option>
                                            {events.map((event) => (
                                                <option value={event.name}>{`${event.name},${event.displayStart}`}</option>
                                            ))}
                                        </select>
                                        <br></br>
                                    </label>
                                    <label>
                                        Select the third featured event
                                        <br></br>
                                        <select className="featured-picker" onChange={handleThirdFeaturedChange}>
                                            <option value="none" selected disabled >
                                                Select an Option
                                            </option>
                                            {events.map((event) => (
                                                <option value={event.name} >{`${event.name},${event.displayStart}`}</option>
                                            ))}
                                        </select>
                                        <br></br>
                                    </label>
                                    <button className="submit-button" type='submit' disabled={isLoading}>Update</button>
                                </form>
                                </div>
                                }
                        </>
                    }
                </>
            }
        </>
    )
}