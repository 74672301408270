/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import MetaTags from 'react-meta-tags';
import { db } from '../firebase';

const LocationEventSection = (props) => {
    const { name, events, trackingLink, groupId } = props
    return (
        <div id={name} style={{ marginBottom: '60px' }}>
            {events && events.length > 0 &&
                <>
                    <a className='section-title' href={`/location/${groupId}`}><h3>{name} {'→'}</h3></a>
                    <div className='location-events'>
                        <div className='continued-events'>
                            {events.map((event, index) => (
                                <a className='featuredLinkLarge' href={trackingLink ? "https://posh.vip/e/" + event.url + "?t=" + trackingLink : "https://posh.vip/e/" + event.url}>
                                    <div className="ftce">
                                        <img src={event.flyer} className="featured-events-cont__events__main__flyer" />
                                        <div className="featured-events-cont__events__main__filter" />
                                        <img className="ftce-icon" />
                                        <h3 className="ftce-title">{event.name}</h3>
                                        <div className="ftce-desc">{event.displayStart}</div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default function Index() {
    const groupIds = ['61b8e25d13376c0033b66a51', '61ba489a42c0810033fe9e1f']
    const companyName = 'Freehold Hospitality'
    const socialShareLink = 'https://poshtesting.s3.amazonaws.com/synchro-events/social-share.jpg'
    const metaDescription = "Freehold Hospitality - COME AS YOU ARE"
    const metaTitle = 'Freehold Hospitality - COME AS YOU ARE'
    const website = 'https://www.freeholdbrooklyn.com/'
    const [trackingLink, setTrackingLink] = useState('')
    const [isEvents, setIsEvents] = useState(false);
    const [allLoadedEvents, setAllEvents] = useState({});
    const [brooklynEvents, setBrooklynEvents] = useState({})
    const [miamiEvents, setMiamiEvents] = useState({})
    // const [isMiamiEvents, setIsMiamiEvents] = useState(false)
    const [isBrooklynEvents, setIsBrooklynEvents] = useState(false)
    const [featuredEvents, setFeaturedEvents] = useState({});
    const [isFeatured, setIsFeatured] = useState(false)
    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        // await fetch(`/poshAPI/${groupIds[0]}`)
        //     .then(async (res) => await res.json())
        //     .then(async (eventsAPI) => {
        //         if (eventsAPI.events) setMiamiEvents(eventsAPI.events);
        //         setIsMiamiEvents(true)

        //     })
        await fetch(`/poshAPI/${groupIds[1]}`)
            .then(async (res) => await res.json())
            .then(async (eventsAPI) => {
                if (eventsAPI.events) setBrooklynEvents(eventsAPI.events);
                setIsBrooklynEvents(true)
            })
        if (query.get('t')) {
            setTrackingLink(query.get('t'))
        }
    }, [isEvents]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        var allEvents = [].concat.apply([], [brooklynEvents])
        setAllEvents(allEvents)
        setIsEvents(true)

        const arr = [1,2,3]
        const promises = arr.map(async (_, i) => {
            const doc = await db.collection('featured-items').doc(i.toString()).get()
            // eslint-disable-next-line no-loop-func
            if (doc.exists) {
                const featEvent = allEvents.find(e => e.url === doc.data().url)
                if (featEvent) return featEvent 
            }         
        })
        const featEv = await Promise.all(promises)
        const featEventsWithoutNull = featEv.filter(e => e)
        if (featEventsWithoutNull.length < 3) {
            const eventCountToFulfill = 3 - featEventsWithoutNull.length
            const eventsToFill = allEvents.filter(e => !featEv.includes(e))
            const eventsToAdd = eventsToFill.slice(0, eventCountToFulfill)
            if (eventsToAdd && eventsToAdd.length > 0) featEventsWithoutNull.push(...eventsToAdd)
        }
        setFeaturedEvents(featEventsWithoutNull)
        setIsFeatured(true)        

    }, [brooklynEvents.length > 0])

    return (
        <>
            <MetaTags>
                <title>{companyName}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="/assets/img/favicon.png" />
                <link href="/css/index.css" rel="stylesheet" />
                <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={socialShareLink} />
                <meta property="og:url" content={website} />
                <meta property="og:type" content="website" />
                <meta property="twitter:title" content={metaTitle} />
                <meta property="twitter:description" content={metaDescription} />
                <meta property="twitter:image" content={socialShareLink} />
                <meta name="twitter:card" content="summary_large_image"></meta>
                <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Raleway" />
            </MetaTags>
            {(isFeatured && featuredEvents && featuredEvents.length > 0) ?
                <div className='page'>
                    <Header></Header>
                    <div className style={{ marginTop: '40px', position: 'relative', width: "100%", minHeight: "500px", display: 'flex', justifyContent: 'center' }}>
                        <div className="body-inner-b">
                            <>
                                <h3>FEATURED EVENTS</h3>
                                <div className='featuredWrapper'>
                                    {featuredEvents && featuredEvents.map((event, index) => (
                                        <>
                                            {index < featuredEvents.length &&
                                                <>
                                                    {featuredEvents[index] &&
                                                        <a className='featuredLinkLarge' href={trackingLink ? "https://posh.vip/e/" + event.url + "?t=" + trackingLink : "https://posh.vip/e/" + event.url}>
                                                            <div className="ftce">
                                                                <img src={event.flyer} className="featured-events-cont__events__main__flyer" />
                                                                <div className="featured-events-cont__events__main__filter" />
                                                                <img className="ftce-icon" />
                                                                <h3 className="ftce-title">{event.name}</h3>
                                                                <div className="ftce-desc">{event.displayStart}</div>
                                                            </div>
                                                        </a>
                                                    }
                                                </>
                                            }
                                            {/* {featuredEvents && featuredEvents.filter(f => f.url).length < 3 &&
                                                <>
                                                    {allLoadedEvents && allLoadedEvents.map((event, index) => (
                                                        <>
                                                            {index < (3 - featuredEvents.length) &&
                                                                <a className='featuredLinkLarge' href={trackingLink ? "https://posh.vip/e/" + event.url + "?t=" + trackingLink : "https://posh.vip/e/" + event.url}>
                                                                    <div className="ftce">
                                                                        <img src={event.flyer} className="featured-events-cont__events__main__flyer" />
                                                                        <div className="featured-events-cont__events__main__filter" />
                                                                        <img className="ftce-icon" />
                                                                        <h3 className="ftce-title">{"FUCK"}</h3>
                                                                        <div className="ftce-desc">{event.displayStart}</div>
                                                                    </div>
                                                                </a>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            } */}
                                        </>
                                    ))}
                                </div>
                                {/* {isMiamiEvents && <LocationEventSection name='MIAMI' events={miamiEvents} trackingLink={trackingLink} groupId={groupIds[0]} />} */}
                                {isBrooklynEvents && <LocationEventSection name='BROOKLYN' events={brooklynEvents} trackingLink={trackingLink} groupId={groupIds[1]} />}
                            </>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
                :
                <div className='boat-wrapper'>
                    <img className='boatWheel' src={'https://poshtesting.s3.amazonaws.com/freehold/FREEHOLD-LOGO.png'} />
                </div>
            }
        </>
    )
}
