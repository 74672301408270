import firebase from 'firebase/app'
import "firebase/firestore"

const app = firebase.initializeApp({
  apiKey: "AIzaSyDmyVR-Vi5M4dNn0Wkqq56i6OKVYdE3vjA",
  authDomain: "freehold-d4abf.firebaseapp.com",
  projectId: "freehold-d4abf",
  storageBucket: "freehold-d4abf.appspot.com",
  messagingSenderId: "803012807932",
  appId: "1:803012807932:web:886f54b8f2f48c0e961b79",
  measurementId: "G-T1818TYPNY"
})



export const db = app.firestore()
export default app;