import React from 'react'

export default function Footer() {
    const companyName = 'Freehold Hospitality'
    const instagram = 'thefreehold'
    const email = 'contactus@freeholdbrooklyn.com'
    const logo = 'https://poshtesting.s3.amazonaws.com/freehold/FREEHOLD-BLACK.png'

    return (
        <>
            <footer>
                <div className="footer__inner">
                    <div className="footer__inner__menus">
                        <div>
                            <img src={logo}></img>
                            <a href={'https://www.instagram.com/'+instagram} target='__blank'>Instagram</a>
                            <a href='https://posh.vip/tos'>Terms of Service</a>
                            <a href='https://posh.vip/privacy'>Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
